import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageModal = ({ open, onClose, imageUrl }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Image Preview</span>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* Zoomed Image Display */}
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          style={{ cursor: 'zoom-in' }}
        >
          <img 
            src={imageUrl} 
            alt="Event visual"  // Updated alt attribute to describe the content better
            style={{
              width: '100%', 
              height: 'auto', 
              objectFit: 'contain', 
              maxHeight: '500px',
            }} 
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
