import { supabase } from "../client";
import { showToastFailure, showToastSuccess } from "./toast";


export const getEvents = async () => {
  try {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      throw error;
    }

    // Check if 'image_url' exists and prepend the Supabase URL dynamically
    const eventsWithImageUrls = data.map((event) => {
      if (event.image_url) {
        event.image_url = `${process.env.REACT_APP_SUPABASEURL}/storage/v1/object/public/images/events/${event.image_url}`;
      }
      return event;
    });
    return eventsWithImageUrls;
  } catch (error) {
    showToastFailure(error.message);
    return [];
  }
}

function createRandomString(length) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export const updateEvent = async (event) => {
  try {
    const { data, error } = await supabase
      .from('events')
      .update({
        name: event?.name,
        description: event?.description,
        is_active: event?.is_active,
        price: event?.price,
        no_of_tickets: event?.no_of_tickets,
        event_start_date_time: event?.event_start_date_time,
        event_end_time: event?.event_end_time,
      })
      .eq('id', event.id).select()
    if (error) {
      showToastFailure(error.message || "Unable to update, please refresh screen")
      throw error;
    }
    showToastSuccess("Data Updated Successfully")
    return data;
  } catch (error) {
    showToastFailure(error.message);
    return null;
  }
};

export const useCreateEvent = () => {

  const uploadImage = async (imageFile) => {
    if (!imageFile) return null;

    try {
      const fileExt = imageFile.name.split('.').pop();
      const fileName = `${createRandomString(10)}.${fileExt}`;
      const folderPath = `events/${fileName}`;


      let { error: uploadError } = await supabase.storage
        .from('images')
        .upload(folderPath, imageFile);

      if (uploadError) {
        showToastFailure('Failed to upload image. Please try again.');
        return null;
      }

      return fileName;
    } catch (error) {
      showToastFailure('Error uploading image.');
      return null;
    }
  };

  const createEvent = async (event) => {
    try {
      let imageUrl = null;

      // Upload image if provided
      if (event.image) {
        imageUrl = await uploadImage(event.image);
        if (!imageUrl) {
          throw new Error('Image upload failed.');
        }
      }


      const { data, error } = await supabase
        .from('events')
        .insert({
          name: event?.name,
          description: event?.description,
          is_active: true,
          price: event?.price,
          no_of_tickets: event?.no_of_tickets,
          event_start_date_time: event?.event_start_date_time,
          event_end_time: event?.event_end_time,
          qr: createRandomString(10),
          image_url: imageUrl,
        })
        .select();

      if (error) {
        showToastFailure(error.message || 'Unable to insert, please try again.');
        throw error;
      }

      showToastSuccess('Event created successfully.');
      return data;
    } catch (error) {
      showToastFailure(error.message);
      return null;
    }
  };

  return { createEvent };
};

export const getOrderEventDetails = async (eventId) => {
  try {
    const { data, error } = await supabase.rpc('get_order_event_details', {event_id_input : eventId});
    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    console.error('Error fetching order event details:', error.message);
    return null;
  }
};


